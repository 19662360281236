import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = ({ location }) => (
  <Layout location={location} title="Thank You">
    <SEO title="Thank You" />
    <section id="earthseeds-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <h3 className="text-center">
            We have received your message and will get back to you soon.
          </h3>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <Link className="btn btn-normal " to="/">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ThankYouPage
